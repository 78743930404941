import React from 'react';
import { NextPage } from 'next';
import styles from './Toast.module.scss';

export type ToastProps = {
  message: string;
};

const Toast: NextPage<ToastProps> = (props: ToastProps) => {
  const { message } = props;

  return (
    <div>
      <div className={styles.modal}>{message}</div>
      <div className={styles.mask}></div>
    </div>
  );
};

export default Toast;
