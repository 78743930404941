/* eslint no-console: off */

import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { LoginErrors, LoginResponse, login as loginApi, loginWithSmsCode } from '../../lib/requests/login';
import { Config } from '../../lib/config';
import InputField, { InputFieldIcon, InputFieldType } from '../InputField';
import styles from './App.module.scss';
import Button from '../Button';
import {
  aesDecrypt,
  getEncryptionKey,
  isOverseaAccount,
  logger,
  MPCode,
  MPRedirectType,
  openUrlWin,
  redirectBackToMP,
  transformToError,
  UrlParams,
  generateCaptchaToken,
} from '../../lib/util';
import { sendSmsCode, SmsResponse } from '../../lib/requests/sms';
import {
  BindResponseCodes,
  PASSWORD_REGEX,
  PHONE_NUMBER_CN_REGEX,
  VALIDATING_SMS_CODE_REGEX,
  VALIDATING_SMS_COOL_DOWN,
  TOTP_EXPIRE_DURATION_DISPLAY,
  AppMessage,
  PageTypeFromMP,
  BINDING_SMS_CODE_LENGTH,
} from '../../lib/constant';
import {
  bind as bindApi,
  BindErrorExtPayload2FARequired,
  BindResponse,
  BindValidation2FAMethod,
} from '../../lib/requests/bind';
import { guest as guestApi, GuestResponse, MockAction as GuestMockAction } from '../../lib/requests/guest';
import Modal, { ModalProps } from '../Modal';
import { AxiosError, AxiosResponse } from 'axios';
import { WCLAppMwError, WCLAppMwErrorResponse } from '../../lib/requests/type';
import Head from 'next/head';
import SmsCode6 from '../SmsCode6';
import iconBack from '../../../public/icons/back@3x.png';
import imgLoginTitle from '../../../public/login_title.png';
import Image from 'next/image';
import Toast, { ToastProps } from '../Toast';
import GeetestCaptcha from '../GeetestCaptcha';
import { Captcha, CaptchaError } from '../GeetestCaptcha';
import { queryCaptchaToggle } from '../../lib/requests/toggle';
import useSWR from 'swr';

enum PageDisplayStatus {
  'LoginPage' = 'LoginPage',
  'PhoneValidatingPage' = 'PhoneValidatingPage',
  'BindingPage' = 'BindingPage',
}

interface AppProps {
  config: Config;
}

const App = (props: AppProps) => {
  const { config } = props;
  // -*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-
  // - GLOBAL
  // -*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-
  const [display, setDisplay] = useState<PageDisplayStatus>(PageDisplayStatus.LoginPage);
  const [needBinding, setNeedBinding] = useState<boolean>(false);
  const [modalProps, setModalProps] = useState<ModalProps | undefined>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [toastProps, setToastProps] = useState<ToastProps | undefined>();
  const [toastVisible, setToastVisible] = useState<boolean>(false);
  let loginRes: LoginResponse;
  const [loginState, setLoginState] = useState<LoginResponse | undefined>();
  const [urlParams, setUrlParams] = useState<UrlParams | undefined>();
  const [globalError, setGlobalError] = useState<string>('');
  const [bindPhoneNoStatus] = useState<{ isBind: boolean }>({ isBind: false });
  const [tempToken] = useState<string>(
    'eyJwcm92aWRlciI6IkdFRVRFU1QiLCJ2ZXJzaW9uIjoiNCIsImRhdGEiOnsiY2FwdGNoYV9pZCI6IjEzYmFiMzEwZDY3ODA0ZjY2M2JhYzUzZmM5MDMwNmNmIiwibG90X251bWJlciI6IjJlMzBmNTM5NTU3ZTQ1MGU5MDM3NThmNGQ4MzZkNTg5IiwicGFzc190b2tlbiI6Ijc3ZTIzZGNmMTY1ZDNlMDZmM2E1ZjNkYmIxNmNjOGJiMTRjODBkY2MyNGVhZjYzOWE0MmIzNjMxYTc4YTgzN2MiLCJnZW5fdGltZSI6IjE3MDA0NjQyNzciLCJjYXB0Y2hhX291dHB1dCI6Ilc1Z3I2ZW1PVGp5bnd6UDBsV3RTQ05GSC01NjRSaWN2b3BkNkIxSGZiZTVEYVhkeGg0WS13aDYxNkN2MXp5QXFRY29jZ05rM1IxZW9BbXZmeG5KalRzRml6Y0pTVVFQWVgzR1ZCajVha25YUWdKdUZlVXdseGxKcFJhd1JnbHhVcW1LU1pjeVNORnI5eXNESDdWR2lLWmFMTXI2M3I1WThHQzYzSFBiQWlUY1YzZzU5VjZ0dFRBM2tTWTZSVW0wX1ZVcEJiRGoyaVdDekVjanVoVFNUVk5tM0tSRUVrREhMVEMxd1dJRjEtdFphelJfQnZjYUE3VmlpdzM5azRuTzM5dXg2c3l5RWxHRVFza0cyeW1Fcml3PT0ifX0='
  );
  const { data: swrToggleEnabled, isLoading: isSwrLoading } = useSWR<boolean, boolean>(config, queryCaptchaToggle);

  const getCaptchaConfig = () => {
    return {
      captchaId:
        display === PageDisplayStatus.PhoneValidatingPage
          ? config.geetestCaptchaIdForSms
          : config.geetestCaptchaIdForLogin,
      product: 'bind',
      language: config.geetestLanguage,
    };
  };

  const toggleModal = useCallback(() => {
    logger.debug('App::toggleModal', 'toggle', { from: modalVisible, to: !modalVisible });
    setModalVisible((visible) => !visible);
  }, [modalVisible]);

  const displayModal = (props: ModalProps) => {
    logger.debug('App::displayModal', 'display', { props, visible: true });
    setModalProps(props);
    setModalVisible(true);
  };

  const renderModal = () => {
    return modalProps !== undefined ? (
      <Modal
        title={modalProps.title}
        text={modalProps.text}
        toggleDisplay={toggleModal}
        buttonConfirm={modalProps.buttonConfirm}
        buttonClose={modalProps.buttonClose}
      />
    ) : (
      ''
    );
  };

  const displayToast = (props: ToastProps) => {
    logger.debug('App::displayToast', 'display', { props, to: true });
    setToastProps(props);
    setToastVisible(true);
    const handle = setTimeout(() => {
      logger.debug('App::displayToast', 'timeout, closing', { to: false });
      clearTimeout(handle);
      setToastVisible(false);
    }, 3000); // 3s
  };

  const renderToast = () => {
    return toastProps !== undefined ? <Toast message={toastProps.message} /> : '';
  };

  const displayAppMessage = useCallback(
    (type: AppMessage, redirectData?: unknown) => {
      switch (type) {
        case AppMessage.LOGIN_AUTH_FAILED:
          displayModal({
            title: '登录错误',
            text: '抱歉，系统无法识别您的用户名和密码，请再次输入。请留意：5次输入错误后，您的账户可能被锁定，可以通过“忘记密码“重置。',
            toggleDisplay: toggleModal,
          });
          break;
        case AppMessage.LOGIN_USERNAME_INVALID:
          displayModal({
            title: '请输入正确的会员号或会员名',
            text: '您输入的会员号或会员名长度不足4个字符，请重新输入。',
            toggleDisplay: toggleModal,
          });
          break;
        case AppMessage.LOGIN_PASSWORD_LENGTH_INVALID:
          displayModal({
            title: '请输入正确的密码',
            text: '您输入的密码长度不符合要求，请重新输入。',
            toggleDisplay: toggleModal,
          });
          break;
        case AppMessage.LOGIN_PASSWORD_FORMAT_INVALID:
          displayModal({
            title: '请输入正确的密码',
            text: '您输入的密码字符不符合要求，请确认大小写及字符类型并重新输入。',
            toggleDisplay: toggleModal,
          });
          break;
        case AppMessage.LOGIN_TOO_MANY_SMS_ATTEMPTS:
          displayModal({
            title: '需要帮助吗？请联系我们',
            text: '若在双重身份验证使用中有任何疑问，请拨打全国客户服务热线咨询。400-104-5885',
            toggleDisplay: toggleModal,
          });
          break;
        case AppMessage.LOGIN_SMS_CODE_VALIDATION_WITHOUT_SEND:
          displayModal({
            title: '提示',
            text: '请输入正确的手机号和验证码。',
            toggleDisplay: toggleModal,
          });
          break;
        case AppMessage.SEND_SMS_TOO_FREQUENT:
          displayModal({
            title: '错误',
            text: '抱歉！您目前申请获取验证码过于频繁，请稍后再试。',
            toggleDisplay: toggleModal,
          });
          break;
        case AppMessage.SEND_SMS_INVALID_PHONE:
          displayModal({
            title: '错误',
            text: '请输入正确的手机号。',
            toggleDisplay: toggleModal,
          });
          break;
        case AppMessage.JS_CODE_EXPIRED:
          displayModal({
            title: '提示',
            text: '您的微信信息已过期，请重新选择微信登录/绑定。',
            buttonConfirm: {
              text: '重试',
              onClick: () => {
                redirectBackToMP(MPRedirectType.CODE_EXPIRE_RETRY, 'expired, retry');
              },
            },
            buttonClose: {
              text: '取消',
              onClick: () => {
                redirectBackToMP(MPRedirectType.CODE_EXPIRE_CANCEL, 'expired, cancel');
              },
            },
            toggleDisplay: toggleModal,
          });
          break;
        case AppMessage.BINDING_CODE_INVALID:
          setIsSmsCode6Error(true);
          setGlobalError('您输入的验证码无效或是已过期，请输入正确的验证码或重新获取。');
          break;
        case AppMessage.BINDING_CONFLICT:
          displayModal({
            title: '绑定不成功',
            text: '您的希尔顿荣誉客会会员或微信账号已绑定，请先解绑后再进行绑定。',
            buttonConfirm: {
              text: '确定',
              onClick: () => {
                redirectBackToMP(MPRedirectType.BINDING_CONFLICT, 'binding conflict', redirectData);
              },
            },
            toggleDisplay: toggleModal,
          });
          break;
        case AppMessage.BINDING_TOO_MANY_ATTEMPTS:
          displayModal({
            title: '需要帮助吗？请联系我们',
            text: '若在双重身份验证使用中有任何疑问，请拨打全国客户服务热线咨询。400-104-5885',
            buttonConfirm: {
              text: '确定',
              onClick: () => {
                redirectBackToMP(MPRedirectType.BINDING_FAILED, 'binding too many attempts', redirectData);
              },
            },
            toggleDisplay: toggleModal,
          });
          break;
        case AppMessage.BINDING_TOO_MANY_REQUESTS:
          displayModal({
            title: '更新错误',
            text: '双重身份验证暂时不可用，请稍后再试。',
            buttonConfirm: {
              text: '确定',
              onClick: () => {
                redirectBackToMP(MPRedirectType.BINDING_FAILED, 'binding too many requests', redirectData);
              },
            },
            toggleDisplay: toggleModal,
          });
          break;
        case AppMessage.BINDING_DONE:
          displayModal({
            title: '提示',
            text: '您已成功绑定微信账号！',
            buttonConfirm: {
              text: '确定',
              onClick: () => {
                redirectBackToMP(MPRedirectType.BINDING_DONE, 'binding done', redirectData);
              },
            },
            toggleDisplay: toggleModal,
          });
          break;
        case AppMessage.BINDING_FAILED:
          displayModal({
            title: '绑定不成功',
            text: '出了些错误，请稍后重试。',
            buttonConfirm: {
              text: '知道了',
              onClick: () => {
                redirectBackToMP(MPRedirectType.BINDING_FAILED, 'binding unknown error', redirectData);
              },
            },
            toggleDisplay: toggleModal,
          });
          break;
        case AppMessage.OVERSEA_ACCOUNT:
          displayModal({
            title: '更新错误',
            text: '您的账户为海外账户，目前暂不支持微信绑定',
            buttonConfirm: {
              text: '确定',
              onClick: () => {
                redirectBackToMP(MPRedirectType.OVERSEA_ACCOUNT, 'oversea account not supported', redirectData);
              },
            },
            toggleDisplay: toggleModal,
          });
          break;
        case AppMessage.NO_VALIDATION_METHOD:
          displayModal({
            title: '更新错误',
            text: '您的手机尚未验证，请先使用您的中国大陆手机进行验证',
            buttonConfirm: {
              text: '确定',
              onClick: () => {
                toggleModal();
                goToPhoneValidating();
              },
            },
            toggleDisplay: toggleModal,
          });
          break;
        case AppMessage.DEFAULT:
        default:
          displayModal({
            title: '提示',
            text: '出了些错误，请稍后重试。',
            buttonConfirm: { text: '知道了' },
            toggleDisplay: toggleModal,
          });
          break;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [toggleModal]
  );

  const monitorMPCodeExpiring = useCallback(
    (decodedCode: MPCode) => {
      // eslint-disable-next-line prefer-const
      let handle: NodeJS.Timer;
      const handleExpired = () => {
        logger.info('App::monitorMPCodeExpiring', 'expired', { decodedCode, now: new Date().toString() });
        clearInterval(handle);
        displayAppMessage(AppMessage.JS_CODE_EXPIRED);
      };

      // already expired, return directly
      if (decodedCode.expire <= Date.now()) {
        return handleExpired();
      }
      // set timer
      handle = setInterval(() => {
        if (decodedCode.expire <= Date.now()) {
          handleExpired();
        }
      }, 1000); // 1s
    },
    [displayAppMessage]
  );

  useEffect(() => {
    const params = window.urlParams.get('data');
    if (!params) {
      logger.error('App::useEffect', 'url param "data" missing', 'no data');
    } else {
      try {
        const parsedKey = getEncryptionKey() as Uint8Array;
        const parsedParams = aesDecrypt(parsedKey, params) as UrlParams;
        logger.info('App::useEffect', 'urlParams', { parsedParams });
        logger.info('App::useEffect', 'mpCode expire at', { time: new Date(parsedParams.code.expire).toString() });
        monitorMPCodeExpiring(parsedParams.code);
        setUrlParams(parsedParams);
        if (parsedParams.uid) {
          setUsername(parsedParams.uid);
        }
      } catch (err) {
        logger.error('App::useEffect', 'error parsing url param "data"', { err });
      }
    }

    const isBindingPage = window.location.href.includes('/bind');
    logger.info('App::useEffect', 'isBindingPage', { isBindingPage });
    setNeedBinding(isBindingPage);

    logger.debug('App::useEffect', 'config', config);
  }, [monitorMPCodeExpiring, setUrlParams, setNeedBinding, config]);

  const fetchGuest = async (login: LoginResponse, mock?: GuestMockAction): Promise<GuestResponse | undefined> => {
    if (!login) {
      return;
    }
    return guestApi(config, login.access_token, login.UserClaims.guestId, mock);
  };

  // -*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-
  // - LOGIN PAGE
  // -*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-
  const goToLogin = () => {
    setDisplay(PageDisplayStatus.LoginPage);
  };

  const [username, setUsername] = useState<string>('');
  const [isUsernameError, setIsUsernameError] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [isPasswordError, setIsPasswordError] = useState<boolean>(false);
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const [termsAgreed, setTermsAgreed] = useState<boolean>(false);
  const findPwdClipboardRef = useRef<HTMLInputElement>(null);

  const isUsernameDisabled = () => {
    return urlParams && urlParams?.uid !== undefined;
  };

  const checkTerms = () => {
    setTermsAgreed((prev) => !prev);
  };

  const validateLoginForm = (): boolean => {
    if (username.length === 0) {
      setIsUsernameError(true);
      return false;
    }
    if (password.length === 0) {
      setIsPasswordError(true);
      return false;
    }
    if (username.length < 4) {
      displayAppMessage(AppMessage.LOGIN_USERNAME_INVALID);
      return false;
    }
    if (password.length < 8 || password.length > 32) {
      displayAppMessage(AppMessage.LOGIN_PASSWORD_LENGTH_INVALID);
      return false;
    }
    if (!PASSWORD_REGEX.test(password)) {
      displayAppMessage(AppMessage.LOGIN_PASSWORD_FORMAT_INVALID);
      return false;
    }
    return true;
  };

  const handleLoginError = (funcName: string, err: unknown) => {
    const transformed = transformToError(err);
    if (!transformed.isAxiosError) {
      logger.error(`App::${funcName}::handleLoginError`, 'not axios err', err);
      displayAppMessage(AppMessage.DEFAULT);
      return;
    }
    const response = (transformed.error as AxiosError).response as AxiosResponse<WCLAppMwErrorResponse> | undefined;
    const error: WCLAppMwError | undefined = response?.data.error;

    if (response === undefined || error === undefined || !Object.keys(LoginErrors).includes(error.code.toString())) {
      logger.error(`App::${funcName}::handleLoginError`, 'wrong structure err', err);
      displayAppMessage(AppMessage.DEFAULT);
      return;
    }

    switch (error.code) {
      case 41002:
        logger.info(
          `App::${funcName}::handleLoginError`,
          'got USER_NOT_VALID error, means no 2FA validation methods, go to validating page'
        );
        goToPhoneValidating();
        break;
      case 42003:
        logger.debug(`App::${funcName}::handleLoginError`, 'sms too many attempts');
        displayAppMessage(AppMessage.LOGIN_TOO_MANY_SMS_ATTEMPTS);
        break;
      case 42005:
        logger.debug(`App::${funcName}::handleLoginError`, 'validation without sending the sms');
        displayAppMessage(AppMessage.LOGIN_SMS_CODE_VALIDATION_WITHOUT_SEND);
        break;
      case 42011:
        logger.debug(`App::${funcName}::handleLoginError`, 'sms code invalid');
        setIsValidatingSmsCodeError(true);
        setGlobalError('请输入正确的手机号验证码');
        break;
      case 63001:
        logger.debug(`App::${funcName}::handleLoginError`, 'auth failed');
        displayAppMessage(AppMessage.LOGIN_AUTH_FAILED);
        break;
      case 40010:
      case 49800:
        logger.debug(`App::${funcName}::handleLoginError`, 'captcha retry');
        break;
      default:
        logger.error(`App::${funcName}::handleLoginError`, 'unknown err', err);
        break;
    }
  };

  const login = async (captchaToken?: string) => {
    // status check
    if (isLogin) {
      return; // still running, do not run it again
    }
    setIsLogin(true);

    let res;
    try {
      // login
      res = await loginApi(config, username, password, captchaToken);
      loginRes = res;
      setLoginState(res);
    } catch (err) {
      handleLoginError('login', err);
      return; // skip next steps anyway, if any error
    } finally {
      logger.info('App::login', 'reset "isLogin"');
      setIsLogin(false);
    }

    // has validated contacts, also on binding page, go to next step, binding with totp
    if (needBinding) {
      await goToBinding(res);
    } else {
      // has validated contacts, not on binding page, return to MP
      logger.debug('App::login', 'login done return to MP', { res });
      redirectBackToMP(MPRedirectType.LOGIN_DONE, 'login done', res);
    }
  };

  const copyFindPwdUrlToClipboard = () => {
    const message = '网址已经复制到剪贴板，请到浏览器中打开';
    const ele = findPwdClipboardRef.current as HTMLInputElement;
    ele.select();

    document.execCommand('copy');
    logger.debug('App::copyFindPwdUrlToClipboard', 'copied', { message });
    displayToast({
      message,
    });
  };

  const getParamFromURL = (paramName: string) => {
    const originUrl = window.location.href;
    const url = new URL(originUrl);
    const params = new URLSearchParams(url.search);
    const paramToken = params.get(paramName);
    let token = '';
    if (paramToken) {
      token = paramToken;
    }
    return token;
  };

  const redirectToH5Page = () => {
    const channel = getParamFromURL('channel');
    const mc = getParamFromURL('adobe_mc');
    const link = document.createElement('a');
    link.href = `${config.urlPwdRequestPage}&channel=${encodeURIComponent(channel)}&adobe_mc=${encodeURIComponent(mc)}`;
    link.target = '_self';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const captchaForLogin = async () => {
    // input check
    if (!validateLoginForm()) {
      return;
    }
    const toggleEnabled: boolean = isSwrLoading ? true : (swrToggleEnabled as boolean);
    if (!toggleEnabled) {
      login();
      return;
    }
    if (window.captchaForLogin) {
      window.captchaForLogin.showCaptcha(); // For bind mode, call showCaptcha again after success and it will automatically reset, no need to actively call
    } else {
      login(tempToken); // when geetest server is down
      logger.error('App::captchaForLogin', 'Not ready or error', 'Captcha is not ready');
    }
  };

  const captchaInitializing = (captchaResponseObj: Captcha) => {
    captchaResponseObj
      .appendTo('#captcha')
      .onReady(() => {
        logger.debug('App::captchaInitializing', `onReady for ${display}`);
        if (display === PageDisplayStatus.PhoneValidatingPage) {
          window.captchaForSMS = captchaResponseObj;
        } else {
          window.captchaForLogin = captchaResponseObj;
        }
      })
      .onBoxShow(() => {
        logger.debug('App::captchaInitializing', `onBoxShow for ${display}`);
      })
      .onError(function (error: CaptchaError) {
        logger.error('App::captchaInitializing', `onError for ${display}`, error);
      })
      .onSuccess(() => {
        const validateResult = captchaResponseObj.getValidate();
        const captchaToken = generateCaptchaToken(validateResult);
        logger.debug('App::captchaInitializing', `onSuccess for ${display}`);
        if (display === PageDisplayStatus.PhoneValidatingPage) {
          if (bindPhoneNoStatus.isBind) {
            validatePhoneNumber(captchaToken);
          } else {
            sendValidatingSms(captchaToken);
          }
        } else {
          login(captchaToken);
        }
      });
  };

  const renderLoginPage = () => {
    return (
      <Fragment>
        <Head>
          <title>登录</title>
        </Head>
        <div className={styles.loginTitle}>
          <div className={styles.loginTitleLogo}>
            <Image src={imgLoginTitle} alt="title" width="80" height="43" />
          </div>
          <span className={styles.loginTitleFont}>登录希尔顿荣誉客会</span>
        </div>
        {urlParams && urlParams.type === PageTypeFromMP.ENROLL && urlParams?.uid !== undefined && (
          <div className={styles.loginEnrollMessage}>
            您已成功注册，会员号我们已为您自动填写。输入密码登录即可完成微信绑定。
          </div>
        )}
        <InputField
          type={InputFieldType.text}
          placeholder="希尔顿荣誉客会账号"
          icon={InputFieldIcon.username}
          onChange={(e) => {
            setUsername(e.target.value);
          }}
          value={username}
          disabled={isUsernameDisabled()}
          error={{
            state: isUsernameError,
            setState: setIsUsernameError,
          }}
        />
        <InputField
          type={InputFieldType.password}
          placeholder="密码"
          icon={InputFieldIcon.password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          value={password}
          error={{
            state: isPasswordError,
            setState: setIsPasswordError,
          }}
        />
        <div className={styles.loginPwdBack}>
          <span onClick={copyFindPwdUrlToClipboard}>找回会员号</span>
          <span onClick={redirectToH5Page}>忘记密码？</span>
        </div>
        {/* Element display:none couldn't be copied to clipboard, so move this input to far away */}
        <input
          ref={findPwdClipboardRef}
          type="text"
          readOnly={true}
          style={{ position: 'absolute', left: '-1000px', top: '-1000px' }}
          defaultValue={config.urlFindPwd}
        />
        <div id="captcha">
          <GeetestCaptcha config={getCaptchaConfig()} start2Initial={captchaInitializing} />
        </div>
        <Button text="立即登录" disabled={isLogin || !termsAgreed} onClick={captchaForLogin} />
        {globalError && <div className={styles.tipsError}>{globalError}</div>}
        <div className={styles.tips}>
          <div className={styles.round}>
            <input type="checkbox" id="loginTermsId" checked={termsAgreed} onChange={checkTerms} />
            <label htmlFor="loginTermsId"></label>
          </div>
          <span className={styles.tipsFirst}>我确认已经阅读并同意</span>
          <span className={styles.tipsTerms} onClick={() => openUrlWin(config.urlPrivacy)}>
            《全球隐私声明》
          </span>
          ,
          <span className={styles.tipsTerms} onClick={() => openUrlWin(config.urlCookie)}>
            《Cookie声明》
          </span>
          ,
          <span className={styles.tipsTerms} onClick={() => openUrlWin(config.urlDataProtection)}>
            《数据保护条款》
          </span>
          和
          <span className={styles.tipsTerms} onClick={() => openUrlWin(config.urlTCS)}>
            《希尔顿荣誉客会适用条款》
          </span>
          。
        </div>
      </Fragment>
    );
  };

  // -*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-
  // - PHONE VALIDATING PAGE
  // -*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-
  const goToPhoneValidating = () => {
    setDisplay(PageDisplayStatus.PhoneValidatingPage);
  };

  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [isPhoneNumberError, setIsPhoneNumberError] = useState<boolean>(false);
  const [validatingSmsCode, setValidatingSmsCode] = useState<string>('');
  const [isValidatingSmsCodeError, setIsValidatingSmsCodeError] = useState<boolean>(false);
  const [sendValidatingSmsCoolDown, setSendValidatingSmsCoolDown] = useState<number>(0);
  const [sendValidatingSmsCodeText, setSendValidatingSmsCodeText] = useState<string>('获取验证码');
  const [isValidating, setIsValidating] = useState<boolean>(false);
  const [sendValidatingSmsRes, setSendValidatingSmsRes] = useState<SmsResponse | undefined>(undefined);
  let validatingSmsCoolDownCount = 0;

  const isValidatingButtonDisabled = () => {
    return !(
      PHONE_NUMBER_CN_REGEX.test(phoneNumber) && // phone number valid
      VALIDATING_SMS_CODE_REGEX.test(validatingSmsCode) && // sms code valid
      sendValidatingSmsRes !== undefined && // sms sent, res saved
      !isValidating
    );
  };

  const isSendPhoneValidatingButtonDisabled = () => {
    return !PHONE_NUMBER_CN_REGEX.test(phoneNumber) || sendValidatingSmsCoolDown > 0;
  };

  const phoneNumberOnChange = (value: string) => {
    // remove all non-number characters
    setPhoneNumber(value.replace(/[^\d]/gi, ''));
  };

  const handleSendValidatingSmsError = (err: unknown) => {
    const transformed = transformToError(err);
    if (!transformed.isAxiosError) {
      logger.error(`App::handleSendSmsError`, 'not axios err', err);
      displayAppMessage(AppMessage.DEFAULT);
      return;
    }
    const response = (transformed.error as AxiosError).response as AxiosResponse<WCLAppMwErrorResponse> | undefined;
    const error: WCLAppMwError | undefined = response?.data.error;
    if (response === undefined || error === undefined) {
      logger.error(`App::handleSendSmsError`, 'wrong structure err', err);
      displayAppMessage(AppMessage.DEFAULT);
      return;
    }
    switch (error.code) {
      case 42004:
        displayAppMessage(AppMessage.SEND_SMS_TOO_FREQUENT);
        break;
      case 42006:
        displayAppMessage(AppMessage.SEND_SMS_INVALID_PHONE);
        break;
      case 40010:
      case 49800:
        logger.debug(`App::handleCaptchaError`, 'captcha retry');
        break;
      default:
        logger.error('App::handleSendValidatingSmsError', 'unknown err', err);
        displayAppMessage(AppMessage.DEFAULT);
        break;
    }
  };

  const captchaForSendSMS = async () => {
    // input check
    if (validatingSmsCoolDownCount > 0) {
      return; // still in cool down
    }
    if (phoneNumber.length === 0) {
      setIsPhoneNumberError(true);
      return;
    }
    if (!PHONE_NUMBER_CN_REGEX.test(phoneNumber)) {
      displayAppMessage(AppMessage.SEND_SMS_INVALID_PHONE);
      return;
    }
    const toggleEnabled: boolean = isSwrLoading ? true : (swrToggleEnabled as boolean);
    if (!toggleEnabled) {
      sendValidatingSms();
      return;
    }
    if (window.captchaForSMS) {
      bindPhoneNoStatus.isBind = false;
      window.captchaForSMS.showCaptcha(); //For bind mode, call showCaptcha again after success and it will automatically reset, no need to actively call
    } else {
      sendValidatingSms(tempToken); // when geetest server is down
      logger.error('App::captchaForSendSMS', 'Not ready or error', 'Captcha is not ready');
    }
  };

  const captchaForBindPhone = async () => {
    const toggleEnabled: boolean = isSwrLoading ? true : (swrToggleEnabled as boolean);
    if (!toggleEnabled) {
      await validatePhoneNumber();
      return;
    }
    if (window.captchaForSMS) {
      bindPhoneNoStatus.isBind = true;
      window.captchaForSMS.showCaptcha(); //For bind mode, call showCaptcha again after success and it will automatically reset, no need to actively call
    } else {
      await validatePhoneNumber(tempToken); // when geetest server is down
      logger.error('App::captchaForBindPhone', 'Not ready or error', 'Captcha is not ready');
    }
  };

  const sendValidatingSms = async (captchaToken?: string) => {
    const renderCountDown = (remaining: number) => {
      return `${remaining} s`;
    };
    validatingSmsCoolDownCount = VALIDATING_SMS_COOL_DOWN;
    setSendValidatingSmsCoolDown(VALIDATING_SMS_COOL_DOWN);
    setSendValidatingSmsCodeText(renderCountDown(VALIDATING_SMS_COOL_DOWN));
    const handle = setInterval(async () => {
      if (validatingSmsCoolDownCount > 1) {
        validatingSmsCoolDownCount--;
        setSendValidatingSmsCoolDown(validatingSmsCoolDownCount);
        setSendValidatingSmsCodeText(renderCountDown(validatingSmsCoolDownCount));
      } else {
        clearInterval(handle);
        validatingSmsCoolDownCount = 0;
        setSendValidatingSmsCoolDown(0);
        setSendValidatingSmsCodeText('获取验证码');
      }
    }, 1000); // 1s

    try {
      const sendRes = await sendSmsCode(config, phoneNumber, captchaToken);
      setSendValidatingSmsRes(sendRes);
    } catch (err) {
      handleSendValidatingSmsError(err);
    }
  };

  const validatePhoneNumber = async (captchaToken?: string) => {
    if (isValidating) {
      return; // still running
    }
    setIsValidating(true);
    setGlobalError('');

    let res;
    const smsRes = sendValidatingSmsRes as SmsResponse;
    try {
      res = await loginWithSmsCode(
        config,
        username,
        password,
        {
          formId: smsRes.formId,
          prefix: smsRes.prefix,
          mobile: phoneNumber,
          code: validatingSmsCode,
        },
        captchaToken
      );
      loginRes = res;
      setLoginState(res);
    } catch (err) {
      handleLoginError('validatePhoneNumber', err);
      return; // skip next steps anyway, if any error
    } finally {
      logger.info('App::validatePhoneNumber', 'reset "isValidating"');
      setIsValidating(false);
    }

    // has validated contacts, also on binding page, go to next step, binding with totp
    if (needBinding) {
      await goToBinding(res);
    } else {
      // has validated contacts, not on binding page, return to MP
      logger.debug('App::validatePhoneNumber', 'login done return to MP', { res });
      redirectBackToMP(MPRedirectType.LOGIN_DONE, 'login done', res);
    }
  };

  const renderValidatingPage = () => {
    return (
      <Fragment>
        <Head>
          <title>登录</title>
        </Head>
        <div className={styles.validationContainer}>
          <div className={styles.validatingUI}>
            <div className={styles.validatingTitle}>
              <Image
                src={iconBack}
                className={styles.validatingTitleIcon}
                alt="backIcon"
                onClick={goToLogin}
                width="24"
                height="24"
              />
              <span>验证手机号</span>
              <div className={styles.placeholderRight}></div>
            </div>
            <div className={styles.tips}>为了保障您的账户安全，请验证您的手机号</div>
            <InputField
              type={InputFieldType.text}
              icon={InputFieldIcon.phoneCountry}
              placeholder="请输入手机号"
              onChange={(e) => phoneNumberOnChange(e.target.value)}
              value={phoneNumber}
              error={{
                state: isPhoneNumberError,
                setState: setIsPhoneNumberError,
              }}
            />
            <div id="captcha">
              <GeetestCaptcha config={getCaptchaConfig()} start2Initial={captchaInitializing} />
            </div>
            <InputField
              type={InputFieldType.text}
              placeholder="请输入验证码"
              onChange={(e) => {
                const value = e.target.value;
                if (value.length > 6) {
                  return;
                }
                setValidatingSmsCode(value);
              }}
              value={validatingSmsCode}
              error={{
                state: isValidatingSmsCodeError,
                setState: setIsValidatingSmsCodeError,
              }}
              button={{
                text: sendValidatingSmsCodeText,
                disabled: isSendPhoneValidatingButtonDisabled(),
                onClick: captchaForSendSMS,
              }}
            />
            {globalError && <div className={styles.tipsError}>{globalError}</div>}
            <Button text="提交验证" disabled={isValidatingButtonDisabled()} onClick={captchaForBindPhone} />
          </div>
        </div>
      </Fragment>
    );
  };

  // -*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-
  // - BINDING PAGE
  // -*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-
  const goToBinding = async (previousLoginRes?: LoginResponse) => {
    setDisplay(PageDisplayStatus.BindingPage);
    await requestBindCode(previousLoginRes);
  };

  const [bindingDelivery, setBindingDelivery] = useState<BindResponse | undefined>(undefined);
  const [bindingSMSCoolDown, setBindingSMSCoolDown] = useState<number>(0);
  const [bindingSMSCode, setBindingSMSCode] = useState<string>('');
  let bindingSMSCoolDownCount = 0;
  const [isBinding, setIsBinding] = useState<boolean>(false);
  const [isSmsCode6Error, setIsSmsCode6Error] = useState<boolean>(false);

  const handleBindingRes = (funcName: string, res: BindResponse, login: LoginResponse) => {
    switch (res.code) {
      case BindResponseCodes.DONE:
        logger.debug(`App::${funcName}::handleBindingRes`, 'binding done return to MP');
        displayAppMessage(AppMessage.BINDING_DONE, login);
        break;
      case BindResponseCodes.NO_VALIDATION_METHOD:
        logger.debug(`App::${funcName}::handleBindingRes`, 'guest phone and email not validated');
        fetchGuest(login)
          .then((guest) => {
            if (isOverseaAccount(guest)) {
              logger.debug(`App::${funcName}::handleBindingRes`, 'account: Oversea, display not supported message');
              displayAppMessage(AppMessage.OVERSEA_ACCOUNT, login);
            } else {
              logger.debug(`App::${funcName}::handleBindingRes`, 'account: CN, display phone verification message');
              displayAppMessage(AppMessage.NO_VALIDATION_METHOD);
            }
          })
          .catch((err) => {
            logger.error(`App::${funcName}::handleBindingRes`, 'fetchGuest unhandled err', err);
            displayAppMessage(AppMessage.DEFAULT);
          });
        break;
      case BindResponseCodes.VALIDATION_REQUIRED:
        logger.debug(`App::${funcName}::handleBindingRes`, 'binding need totp, sms code sent');
        setBindingDelivery(res);
        break;
      case BindResponseCodes.VALIDATION_FAILED:
        logger.debug(`App::${funcName}::handleBindingRes`, 'binding failed with wrong sms code');
        displayAppMessage(AppMessage.BINDING_CODE_INVALID);
        break;
      case BindResponseCodes.CONFLICT:
        logger.debug(`App::${funcName}::handleBindingRes`, 'binding conflict, already bound');
        displayAppMessage(AppMessage.BINDING_CONFLICT, login);
        break;
      case BindResponseCodes.TOO_MANY_ATTEMPTS:
        logger.debug(`App::${funcName}::handleBindingRes`, 'too many invalid validation attempts');
        displayAppMessage(AppMessage.BINDING_TOO_MANY_ATTEMPTS, login);
        break;
      case BindResponseCodes.TOO_MANY_REQUESTS:
        logger.debug(`App::${funcName}::handleBindingRes`, 'too many totp requests');
        displayAppMessage(AppMessage.BINDING_TOO_MANY_REQUESTS, login);
        break;
      default:
        logger.error(`App::${funcName}::handleBindingRes`, 'unknown res', res);
        displayAppMessage(AppMessage.BINDING_FAILED, login);
        break;
    }
  };

  const requestBindCode = async (previousLoginRes?: LoginResponse) => {
    if (bindingSMSCoolDownCount > 0) {
      return; // still in cool down
    }
    if (previousLoginRes === undefined) {
      previousLoginRes = loginRes || loginState;
    }

    bindingSMSCoolDownCount = VALIDATING_SMS_COOL_DOWN;
    setBindingSMSCoolDown(VALIDATING_SMS_COOL_DOWN);
    const handle = setInterval(async () => {
      if (bindingSMSCoolDownCount > 1) {
        bindingSMSCoolDownCount--;
        setBindingSMSCoolDown(bindingSMSCoolDownCount);
      } else {
        logger.info('App::requestBindCode', 'sms code request cool down done', { now: new Date().toString() });
        clearInterval(handle);
        bindingSMSCoolDownCount = 0;
        setBindingSMSCoolDown(0);
      }
    }, 1000); // 1s

    try {
      if (urlParams === undefined || urlParams.code === undefined) {
        // noinspection ExceptionCaughtLocallyJS
        throw new Error('MP Code not found on page');
      }
      const res = await bindApi(config, (previousLoginRes as LoginResponse).access_token, urlParams.code.code);
      handleBindingRes('requestBindCode', res, previousLoginRes as LoginResponse);
    } catch (err) {
      logger.error('App::requestBindCode', 'error', err);
      displayAppMessage(AppMessage.BINDING_FAILED);
    }
  };

  const bind = async () => {
    if (isBinding) {
      return; // still running
    }
    setIsBinding(true);
    const previousLoginRes = loginRes || loginState;

    try {
      if (urlParams === undefined || urlParams.code === undefined) {
        // noinspection ExceptionCaughtLocallyJS
        throw new Error('MP Code not found on page');
      }
      if (bindingDelivery === undefined) {
        // noinspection ExceptionCaughtLocallyJS
        throw new Error('Binding SMS code delivery data not found');
      }
      const delivery = (bindingDelivery as BindErrorExtPayload2FARequired).delivery;
      const res = await bindApi(config, (previousLoginRes as LoginResponse).access_token, urlParams.code.code, {
        method: delivery.method,
        code: bindingSMSCode,
      });
      handleBindingRes('bind', res, previousLoginRes as LoginResponse);
    } catch (err) {
      logger.error('App::bind', 'error', err);
      displayAppMessage(AppMessage.BINDING_FAILED);
    } finally {
      logger.info('App::bind', 'reset "isBinding"');
      setIsBinding(false);
    }
  };

  const renderBindingSMSCoolDown = () => {
    if (bindingSMSCoolDown === 0) {
      return '';
    }
    return `(${bindingSMSCoolDown}s)`;
  };

  const renderBindingMessage = () => {
    if (bindingDelivery === undefined) {
      return '';
    }
    const delivery = (bindingDelivery as BindErrorExtPayload2FARequired).delivery;
    if (delivery.method === BindValidation2FAMethod.sms) {
      let address = delivery.addressMasked;
      if (address.length > 4) {
        address = address.slice(-4);
      }
      return `我们已经向您尾号为${address}的手机号发送了验证码。`;
    } else if (delivery.method === BindValidation2FAMethod.email) {
      return `我们已经向您的邮箱${delivery.addressMasked}发送了验证码。`;
    } else {
      return '';
    }
  };

  const isBindButtonDisabled = () => {
    if (bindingSMSCode.length < 6) {
      return true;
    }
    return false;
  };

  const isResendBindingCodeDisabled = () => {
    return renderBindingSMSCoolDown() !== '';
  };

  const renderBindingPage = () => {
    return (
      <Fragment>
        <Head>
          <title>增强安全性</title>
        </Head>
        <div className={styles.bindingUI}>
          <div className={styles.loginTitle}>
            <span>您的账号即将被绑定</span>
          </div>
          <div className={styles.bindingMessage}>
            <span>首先，我们需要确认您的身份。</span>
            <span>{renderBindingMessage()}</span>
            <span>
              验证码将在{TOTP_EXPIRE_DURATION_DISPLAY}分钟后过期。如需帮助，请拨打
              <span>400-104-5885</span>。
            </span>
          </div>
          <SmsCode6
            codeLength={BINDING_SMS_CODE_LENGTH}
            code={{ state: bindingSMSCode, setState: setBindingSMSCode }}
            error={{
              state: isSmsCode6Error,
              setState: setIsSmsCode6Error,
            }}
          />
          {globalError && <div className={styles.tipsError}>{globalError}</div>}
          <Button text="完成" disabled={isBindButtonDisabled()} onClick={bind} />
          <button
            className={`${styles.resend} ${isResendBindingCodeDisabled() ? styles.resendDisabled : ''}`}
            disabled={isResendBindingCodeDisabled()}
            onClick={() => requestBindCode()}
          >{`重新获取验证码${renderBindingSMSCoolDown()}`}</button>
        </div>
      </Fragment>
    );
  };

  // -*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-
  // - APP PAGE RENDER
  // -*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-
  return (
    <div className={styles.loginContainer}>
      {/* Modal Dialogue */}
      {modalVisible && renderModal()}
      {/* Toast Dialogue */}
      {toastVisible && renderToast()}
      {/* Login Page */}
      {display === PageDisplayStatus.LoginPage && renderLoginPage()}
      {/* Phone Validating Page */}
      {display === PageDisplayStatus.PhoneValidatingPage && renderValidatingPage()}
      {/* Binding Page */}
      {display === PageDisplayStatus.BindingPage && renderBindingPage()}
    </div>
  );
};

export default App;
