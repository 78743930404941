import { useEffect } from 'react';
import { NextPage } from 'next';
export interface CaptchaResponse {
  captcha_id: string;
  lot_number: string;
  pass_token: string;
  gen_time: string;
  captcha_output: string;
}

export interface CaptchaError {
  // eg:{ code: '60000', msg: 'User Configuration Error', desc: { detail: 'User id is Missing' } }
  code: string; // refer to https://docs.geetest.com/BehaviorVerification/apirefer/errorcode/web
  msg: string;
  desc: { detail: string };
}

export interface Captcha {
  // You can refer to https://docs.geetest.com/BehaviorVerification/apirefer/api/web for the detail info of the Geetest web api
  appendTo: (captchaBox: string) => this;
  getValidate: () => CaptchaResponse;
  reset: () => this;
  showCaptcha: () => this;
  onReady: (callback: () => void) => this;
  onBoxShow: (callback: () => void) => this;
  onError: (callback: (error: CaptchaError) => void) => this;
  onSuccess: (callback: () => void) => this;
  destroy: () => this;
}

export interface CaptchaConfig {
  language: string; // Set the language for the text of verification interface,refer to https://docs.geetest.com/BehaviorVerification/apirefer/api/web
  captchaId: string; // Verify ID, apply it on the dashboard
  product: string; // Set the presentation of the next verification
}

export interface GeetestCaptchaProps {
  config: CaptchaConfig;
  start2Initial: (responseObj: Captcha) => void;
}

const GeetestCaptcha: NextPage<GeetestCaptchaProps> = (props: GeetestCaptchaProps) => {
  useEffect(() => {
    // when geetest server is down, not init
    if (window.initGeetest4) {
      window.initGeetest4(props.config, props.start2Initial);
    }
  });
  return <div className="captcha"></div>;
};

export default GeetestCaptcha;
