import React, { ChangeEvent, Fragment, useState, FocusEvent } from 'react';
import { NextPage } from 'next';
import Image from 'next/image';
import styles from './InputField.module.scss';
import { State } from '../Shared/type';
import iconUsername from '../../../public/icons/login_username.png';
import iconPassword from '../../../public/icons/login_password.png';
import iconPwdVisible from '../../../public/icons/password_visible2.png';
import iconPwdInvisible from '../../../public/icons/password_invisible2.png';

export enum InputFieldIcon {
  username = 'login_username.png',
  password = 'login_password.png',
  phoneCountry = 'PHONE_COUNTRY',
}

enum InputFieldPasswordIcon {
  visible = 'password_visible2.png',
  invisible = 'password_invisible2.png',
}

export enum InputFieldType {
  text = 'text',
  password = 'password',
}

export interface InputFieldRightButton {
  text: string;
  onClick: () => void;
  disabled: boolean;
}

type InputFieldProps = {
  type: InputFieldType;
  placeholder: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string;
  error: State<boolean>;
  disabled?: boolean;
  icon?: InputFieldIcon;
  button?: InputFieldRightButton;
};

const InputField: NextPage<InputFieldProps> = (props: InputFieldProps) => {
  const { type, placeholder, onChange, value, error, icon, button } = props;

  let disabled = false;
  if (props.disabled !== undefined) {
    disabled = props.disabled;
  }

  const [renderType, setRenderType] = useState<InputFieldType>(type);
  const [passwordIcon, setPasswordIcon] = useState<InputFieldPasswordIcon>(InputFieldPasswordIcon.invisible);
  const togglePasswordDisplay = () => {
    if (renderType === InputFieldType.text) {
      setPasswordIcon(InputFieldPasswordIcon.invisible);
      setRenderType(InputFieldType.password);
    } else {
      setPasswordIcon(InputFieldPasswordIcon.visible);
      setRenderType(InputFieldType.text);
    }
  };

  const renderIcon = () => {
    if (icon === undefined) {
      return <Fragment></Fragment>;
    }
    switch (icon) {
      case InputFieldIcon.phoneCountry:
        return (
          <div className={styles.icon}>
            <span>+86</span>
          </div>
        );
      case InputFieldIcon.username:
        return <Image src={iconUsername} className={styles.icon} alt="username" width="24" height="24" />;
      case InputFieldIcon.password:
        return <Image src={iconPassword} className={styles.icon} alt="password" width="24" height="24" />;
      default:
        return <Fragment></Fragment>;
    }
  };

  const renderInput = () => {
    return (
      <input
        className={`${styles.hilFieldWithIcon} ${error.state ? styles.hasError : ''}`}
        type={renderType}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        onFocus={(e: FocusEvent<HTMLInputElement>) => {
          error.setState(false);
          const input = e.target;
          const length = input.value.length;
          if (length) {
            setTimeout(() => {
              input.setSelectionRange(length, length);
            }, 0);
          }
        }}
      />
    );
  };

  const renderPasswordButton = () => {
    if (type !== InputFieldType.password) {
      return <Fragment></Fragment>;
    }
    let icon;
    switch (passwordIcon) {
      case InputFieldPasswordIcon.visible:
        icon = <Image src={iconPwdVisible} alt="pwdVisible" width="24" height="24" />;
        break;
      case InputFieldPasswordIcon.invisible:
        icon = <Image src={iconPwdInvisible} alt="pwdInvisible" width="24" height="24" />;
        break;
      default:
        icon = <Fragment></Fragment>;
        break;
    }
    return (
      <div className={styles.togglePassword} onClick={togglePasswordDisplay}>
        {icon}
      </div>
    );
  };

  const renderRightButton = () => {
    if (button === undefined || type === InputFieldType.password) {
      // password has its own buttons on the right side, so ignore here
      return <Fragment></Fragment>;
    }
    return (
      <button className={styles.hilFieldSendButton} onClick={button.onClick} disabled={button.disabled}>
        {button.text}
      </button>
    );
  };

  return (
    <div className={`${styles.hilField}`}>
      {renderIcon()}
      {renderInput()}
      {renderPasswordButton()}
      {renderRightButton()}
    </div>
  );
};

export default InputField;
