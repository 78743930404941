import { Config } from '../config';
import axios, { AxiosResponse, AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { genCurlFromAxiosOptions, isDev, logger } from '../util';
import { MOCK_CONSTANTS } from '../../../test/mock/app-middleware/constant';
import { WCLAppMwError } from './type';
axios.defaults.adapter = require('axios/lib/adapters/http');

interface LoginRequestWithSmsCode {
  username: string;
  password: string;
  formId: string;
  mobile: string;
  prefix: string;
  code: string;
}

interface LoginRequest {
  username: string;
  password: string;
}

export interface LoginResponse {
  access_token: string;
  UserClaims: {
    guestId: string;
    hhonorsNumber: string;
  };
}

export interface MobileValidationLoginInput {
  formId: string;
  mobile: string;
  prefix: string;
  code: string;
}

export const LoginErrors: Record<string, WCLAppMwError> = {
  '20002': {
    statusCode: 400,
    name: 'ValidationError',
    message: 'data validation error',
    code: 20002,
    refer: 'DATA_VALIDATE_ERROR',
  },
  '41002': {
    statusCode: 403,
    name: 'Error',
    message: 'user not valid to login',
    code: 41002,
    refer: 'USER_NOT_VALID',
  },
  '42003': {
    statusCode: 400,
    name: 'Error',
    message: 'exceeded max attempts',
    code: 42003,
    refer: 'SMS_CODE_EXCEED_MAX_ATTEMPT',
  },
  '42005': {
    statusCode: 400,
    name: 'Error',
    message: 'validation without sending the sms',
    code: 42005,
    refer: 'SMS_CODE_VALIDATION_WITHOUT_SEND',
  },
  '42011': {
    statusCode: 403,
    name: 'Error',
    message: 'sms code not match',
    code: 42011,
    refer: 'SMS_CODE_NOT_MATCH',
  },
  '63001': {
    statusCode: 401,
    name: 'HiltonError',
    message: 'Authentication failed for guests/xxx',
    code: 63001,
    refer: 'SERVICE_HILTON_AUTH_ERROR',
  },
  '49800': {
    statusCode: 498,
    name: 'Error',
    message: 'The contents of the CAPTCHA token is expired or invalid.',
    code: 49800,
    refer: 'CAPTCHA_TOKEN_REJECTED',
  },
  '40010': {
    statusCode: 400,
    name: 'Error',
    message: 'The CAPTCHA token is not provided.',
    code: 40010,
    refer: 'CAPTCHA_TOKEN_REQUIRED',
  },
};

export enum MockAction {
  AUTH_ERROR = 'AUTH_ERROR',
  NO_VALIDATED_PHONE = 'NO_VALIDATED_PHONE',
  SMS_TOO_MANY_ATTEMPTS = 'SMS_TOO_MANY_ATTEMPTS',
  SMS_CODE_VALIDATION_WITHOUT_SEND = 'SMS_CODE_VALIDATION_WITHOUT_SEND',
  CAPTCHA_TOKEN_REJECTED = 'CAPTCHA_TOKEN_REJECTED',
  CAPTCHA_TOKEN_REQUIRED = 'CAPTCHA_TOKEN_REQUIRED',
}

export const loginWithSmsCode = async (
  config: Config,
  username: string,
  password: string,
  mobile: MobileValidationLoginInput,
  captchaToken?: string,
  mock?: MockAction
): Promise<LoginResponse> => {
  logger.debug('API::loginWithSmsCode', 'start', { mock });
  const headers: AxiosRequestHeaders = {
    'Content-Type': 'application/json',
    'x-consumer-groups': config.apiChannel,
    'api-key': config.apiKey,
  };
  if ('bmak' in window) {
    headers['Akamai-BM-Telemetry'] = window.bmak.get_telemetry();
  }
  if (captchaToken) {
    headers['x-captcha-token'] = captchaToken;
  }
  const options: AxiosRequestConfig<LoginRequestWithSmsCode> = {
    method: 'POST',
    baseURL: config.appMiddlewareHost,
    url: config.appMiddlewareMobileLoginEndpoint,
    headers: handleMockHeader(headers, mock),
    data: { username, password, ...mobile } as LoginRequestWithSmsCode,
  };

  try {
    logger.debug('API::loginWithSmsCode', 'curl command:', genCurlFromAxiosOptions(options));
    const res: AxiosResponse<LoginResponse, LoginRequestWithSmsCode> = await axios(options);
    logger.debug('API::loginWithSmsCode', 'res', res.data);
    return res.data;
  } catch (err) {
    logger.error('API::loginWithSmsCode', 'error', err, true);
    throw err;
  }
};

export const login = async (
  config: Config,
  username: string,
  password: string,
  captchaToken?: string,
  mock?: MockAction
): Promise<LoginResponse> => {
  logger.debug('API::login', 'start', { mock });
  const headers: AxiosRequestHeaders = {
    'Content-Type': 'application/json',
    'x-consumer-groups': config.apiChannel,
    'api-key': config.apiKey,
  };
  if ('bmak' in window) {
    headers['Akamai-BM-Telemetry'] = window.bmak.get_telemetry();
  }

  if (captchaToken) {
    headers['x-captcha-token'] = captchaToken;
  }

  const options: AxiosRequestConfig<LoginRequest> = {
    method: 'POST',
    baseURL: config.appMiddlewareHost,
    url: config.appMiddlewareMobileLoginEndpoint,
    headers: handleMockHeader(headers, mock),
    data: { username, password } as LoginRequest,
  };

  try {
    logger.debug('API::login', 'curl command:', genCurlFromAxiosOptions(options));
    const res: AxiosResponse<LoginResponse, LoginRequest> = await axios(options);
    logger.debug('API::login', 'res', res.data);
    return res.data;
  } catch (err) {
    logger.error('API::login', 'error', err, true);
    throw err;
  }
};

const handleMockHeader = (headers: AxiosRequestHeaders, action: MockAction | undefined): AxiosRequestHeaders => {
  if (!isDev()) {
    return headers;
  }
  if (action === undefined) {
    return headers;
  }

  switch (action) {
    case MockAction.AUTH_ERROR:
      headers[MOCK_CONSTANTS.HEADER_X_LOGIN_AUTH_ERROR] = 'true';
      break;
    case MockAction.NO_VALIDATED_PHONE:
      headers[MOCK_CONSTANTS.HEADER_X_LOGIN_NO_VALIDATED_PHONE] = 'true';
      break;
    case MockAction.SMS_TOO_MANY_ATTEMPTS:
      headers[MOCK_CONSTANTS.HEADER_X_LOGIN_SMS_EXCEED_MAX_ATTEMPT] = 'true';
      break;
    case MockAction.SMS_CODE_VALIDATION_WITHOUT_SEND:
      headers[MOCK_CONSTANTS.HEADER_X_LOGIN_SMS_CODE_VALIDATION_WITHOUT_SEND] = 'true';
      break;
    case MockAction.CAPTCHA_TOKEN_REJECTED:
      headers[MOCK_CONSTANTS.HEADER_X_CAPTCHA_TOKEN_REJECTED] = 'true';
      break;
    case MockAction.CAPTCHA_TOKEN_REQUIRED:
      headers[MOCK_CONSTANTS.HEADER_X_CAPTCHA_TOKEN_REQUIRED] = 'true';
      break;
    default:
      break;
  }

  return headers;
};
