export const MOCK_CONSTANTS = {
  // POST LOGIN
  HEADER_X_LOGIN_AUTH_ERROR: 'x-login-auth-error',
  HEADER_X_LOGIN_NO_VALIDATED_PHONE: 'x-login-no-validated-phone', // when user have no validated phone, he has to log in with sms code, otherwise this error will happen
  HEADER_X_LOGIN_SMS_EXCEED_MAX_ATTEMPT: 'x-login-sms-too-many-attempts',
  HEADER_X_LOGIN_SMS_CODE_VALIDATION_WITHOUT_SEND: 'x-login-sms-code-validation-without-send',
  // POST GUEST
  HEADER_X_GUEST_OVERSEA_ADDRESS: 'x-guest-oversea-address',
  // GQL LINK ACCOUNT
  HEADER_X_LINK_VALIDATION_METHOD: 'x-link-validation-method',
  HEADER_X_LINK_TOO_MANY_REQUESTS: 'x-link-too-many-requests',
  HEADER_X_LINK_NO_VALIDATION_METHOD: 'x-link-no-validation-method',
  HEADER_X_LINK_VALIDATION_CONFLICT: 'x-link-validation-conflict',
  HEADER_X_LINK_TOO_MANY_ATTEMPTS: 'x-link-too-many-attempts',
  HEADER_X_LINK_BAD_USER_INPUT: 'x-link-bad-user-input',
  HEADER_X_LINK_INTERNAL_SERVER_ERROR: 'x-link-internal-error',
  HEADER_X_LINK_UNKNOWN_SERVER_ERROR: 'x-link-unknown-error',
  // GQL SEND SMS
  HEADER_X_SMS_WRONG_PHONE: 'x-sms-wrong-phone',
  // CAPTCHA TOKEN
  HEADER_X_CAPTCHA_TOKEN_REJECTED: 'x-captcha-token-rejected',
  HEADER_X_CAPTCHA_TOKEN_REQUIRED: 'x-captcha-token-required',
  // TOGGLE
  HEADER_X_TOGGLE_INTERNAL_SERVER_ERROR: 'x-toggle-internal-error',
  HEADER_X_TOGGLE_RESPONSE_EMPTY: 'x-toggle-response-empty',
  // OTHERS
  TOTP_MAGIC_CODE: '111222',
};
