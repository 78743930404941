import React, { useEffect, useRef } from 'react';
import { NextPage } from 'next';
import styles from './Modal.module.scss';

export interface ModalProps {
  title: string;
  text: string;
  toggleDisplay: () => void;
  buttonConfirm?: ModalButton;
  buttonClose?: ModalButton;
}

export interface ModalButton {
  text: string;
  onClick?: () => void;
}

const Modal: NextPage<ModalProps> = (props: ModalProps) => {
  const { title, text, toggleDisplay, buttonClose } = props;
  let { buttonConfirm } = props;

  const defaultClick = () => {
    toggleDisplay();
  };

  if (buttonConfirm === undefined) {
    buttonConfirm = {
      text: '确定',
      onClick: defaultClick,
    };
  }

  const buttonRef = useRef<HTMLButtonElement>(null);

  // get focused to fix https://jira.hilton.com.cn/browse/HIL-4388
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (buttonRef as any).current.focus();
  });

  return (
    <div>
      <div className={styles.modal}>
        <div className={styles.modalContainer}>
          <div className={styles.modalTitle}>{title}</div>
          <div className={styles.modalContent}>{text}</div>
          <div className={styles.modalOperator}>
            {buttonClose && (
              <button
                className={styles.modalOperatorClose}
                onClick={buttonClose.onClick ? buttonClose.onClick : defaultClick}
              >
                {buttonClose.text}
              </button>
            )}
            <button
              ref={buttonRef}
              className={styles.modalOperatorConfirm}
              onClick={buttonConfirm.onClick ? buttonConfirm.onClick : defaultClick}
            >
              {buttonConfirm.text}
            </button>
          </div>
        </div>
      </div>
      <div className={styles.mask}></div>
    </div>
  );
};

export default Modal;
