import { Config } from '../config';
import { genCurlFromAxiosOptions, isDev, logger } from '../util';
import { MOCK_CONSTANTS } from '../../../test/mock/app-middleware/constant';
import axios, { AxiosResponse, AxiosRequestConfig, AxiosRequestHeaders } from 'axios';

export interface SmsInput {
  mobile: string;
  prefix: '86';
  type: 'LOGIN';
}

export interface SmsResponse {
  formId: string;
  mobile: string;
  prefix: string;
  isSend: boolean;
}

export enum MockAction {
  PHONE_NUM_ERROR = 'PHONE_NUM_ERROR',
  CAPTCHA_TOKEN_REJECTED = 'CAPTCHA_TOKEN_REJECTED',
  CAPTCHA_TOKEN_REQUIRED = 'CAPTCHA_TOKEN_REQUIRED',
}

export const sendSmsCode = async (config: Config, phoneNumber: string, captchaToken?: string, mock?: MockAction) => {
  logger.debug('API::sendSmsCode', 'start', { mock });
  const headers: AxiosRequestHeaders = {
    'Content-Type': 'application/json',
    'x-consumer-groups': config.apiChannel,
    'api-key': config.apiKey,
  };
  if ('bmak' in window) {
    headers['Akamai-BM-Telemetry'] = window.bmak.get_telemetry();
  }

  if (captchaToken) {
    headers['x-captcha-token'] = captchaToken;
  }

  const options: AxiosRequestConfig<SmsInput> = {
    method: 'POST',
    baseURL: config.appMiddlewareHost,
    url: config.appMiddlewareSendSmsEndpoint,
    headers: handleMockHeader(headers, mock),
    data: { mobile: phoneNumber, prefix: '86', type: 'LOGIN' } as SmsInput,
  };

  try {
    logger.debug('API::sendSmsCode', 'curl command:', genCurlFromAxiosOptions(options));
    const res: AxiosResponse<SmsResponse, SmsInput> = await axios(options);
    logger.debug('API::sendSmsCode', 'res', res.data);
    return res.data;
  } catch (err) {
    logger.error('API::sendSmsCode', 'error', err, true);
    throw err;
  }
};

const handleMockHeader = (headers: AxiosRequestHeaders, action: MockAction | undefined): AxiosRequestHeaders => {
  if (!isDev()) {
    return headers;
  }
  if (action === undefined) {
    return headers;
  }

  switch (action) {
    case MockAction.PHONE_NUM_ERROR:
      headers[MOCK_CONSTANTS.HEADER_X_SMS_WRONG_PHONE] = 'true';
      break;
    case MockAction.CAPTCHA_TOKEN_REJECTED:
      headers[MOCK_CONSTANTS.HEADER_X_CAPTCHA_TOKEN_REJECTED] = 'true';
      break;
    case MockAction.CAPTCHA_TOKEN_REQUIRED:
      headers[MOCK_CONSTANTS.HEADER_X_CAPTCHA_TOKEN_REQUIRED] = 'true';
      break;
    default:
      break;
  }

  return headers;
};
