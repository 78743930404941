import { Config } from '../config';
import { gql, request as requestGQL, RequestExtendedOptions as RequestOptionsGQL } from 'graphql-request';
import { genCurlFromGQLOptions, isDev, logger } from '../util';
import { MOCK_CONSTANTS } from '../../../test/mock/app-middleware/constant';
import * as Dom from 'graphql-request/dist/types.dom';

export interface GuestInput {
  guestId: string;
  language: string;
}

export interface GuestResponse {
  guest: {
    guestId: string;
    personalinfo: {
      addresses: {
        addressFmt: string;
        addressId: number;
        addressLine1: string;
        addressLine2: string | null;
        addressLine3: string | null;
        city: string;
        company: string | null;
        country: string;
        countryName: string | null;
        postalCode: string | null;
        preferred: boolean;
        state: string;
        stateName: string | null;
        validated: boolean | null;
      }[];
    };
  };
}

export enum MockAction {
  OVERSEA_ADDRESS = 'OVERSEA_ADDRESS',
}

export const guest = async (
  config: Config,
  auth: string,
  guestId: string,
  mock?: MockAction
): Promise<GuestResponse> => {
  logger.debug('API::guest', 'start', { mock });
  const variables: GuestInput = {
    guestId,
    language: 'zh-CN',
  };

  const options: RequestOptionsGQL<GuestInput> = {
    url: `${config.appMiddlewareHost}${config.appMiddlewareGqlEndpoint}?type=GuestInfo`,
    document: gql`
      query GuestInfo($guestId: BigInt!, $language: String!) {
        guest(language: $language, guestId: $guestId) {
          guestId
          personalinfo {
            addresses {
              addressFmt
              addressId
              addressLine1
              addressLine2
              addressLine3
              city
              company
              country
              countryName
              postalCode
              preferred
              state
              stateName
              validated
            }
          }
        }
      }
    `,
    variables,
    requestHeaders: handleMockHeader(
      {
        authorization: auth,
        'api-key': config.apiKey,
      },
      mock
    ),
  };

  try {
    logger.debug('API::guest', 'curl command:', genCurlFromGQLOptions(options));
    const res = await requestGQL<GuestResponse, GuestInput>(options);
    logger.debug('API::guest', 'res', res);
    return res;
  } catch (err) {
    logger.error('API::guest', 'error', err, true);
    throw err;
  }
};

const handleMockHeader = (
  headers: Record<string, string>,
  action: MockAction | undefined
): Dom.RequestInit['headers'] => {
  if (!isDev()) {
    return headers;
  }
  if (action === undefined) {
    return headers;
  }

  switch (action) {
    case MockAction.OVERSEA_ADDRESS:
      headers[MOCK_CONSTANTS.HEADER_X_GUEST_OVERSEA_ADDRESS] = 'true';
      break;
    default:
      break;
  }

  return headers;
};
