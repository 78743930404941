import React, { useRef, useState, KeyboardEvent } from 'react';
import { NextPage } from 'next';
import styles from './SmsCode6.module.scss';
import { isNumeric, logger } from '../../lib/util';
import { State } from '../Shared/type';
import icInputClear from '../../../public/icons/icInputClear.png';
import Image from 'next/image';
import { BINDING_SMS_CODE_LENGTH } from '../../lib/constant';

type SmsCode6Props = {
  codeLength: number;
  code: State<string>;
  error: State<boolean>;
};

const SmsCode6: NextPage<SmsCode6Props> = (props: SmsCode6Props) => {
  const { codeLength, code, error } = props;

  const [codes, setCodes] = useState<string[]>([]);
  const focusRef = useRef<HTMLInputElement[]>([]);

  const focus = (index: number) => {
    logger.debug('SmsCode6::focus', 'el focused', { index, el: focusRef.current[index] });
    focusRef.current[index]?.focus();
  };

  const setCode = (index: number, value: string) => {
    logger.debug('SmsCode6::setCode', 'cell on change: init', { index, value });
    error.setState(false);

    // get last character
    value = value.trim();
    if (value.length === codeLength) {
      // auto fill from mobile device sms code receiving action
      setAutoFillCodes(value);
      return;
    }
    if (value.length > 1) {
      // onChange value could be:
      // "${newChar}${OldChar}"
      // or
      // "${OldChar}${newChar}"
      // so only "value.slice(-1)" could always get the old value, thus nothing changed on UI
      // need to remove the "oldChar" from onChange value first
      value = value.replace(codes[index], '');
      value = value.slice(-1);
    }
    logger.debug('SmsCode6::setCode', 'cell on change: fixed', { index, value });

    // check value is number
    if (!isNumeric(value)) {
      logger.debug('SmsCode6::setCode', 'cell on change: not valid character');
      return;
    }

    // set codes value
    const copiedCodes = [...codes];
    copiedCodes[index] = value;
    setCodes(copiedCodes);

    // set global code string
    const globalCode = copiedCodes.join('');
    code.setState(globalCode);
    logger.debug('SmsCode6::setCode', 'cell on change: final', { index, value, code: globalCode });

    // move focus to next
    // total 6 cells, max is 5
    if (index + 1 < 6) {
      focus(index + 1);
    }
  };

  const setAutoFillCodes = (value: string) => {
    setCodes(value.split(''));
    code.setState(value);
    focus(5); // move focus to the last cell
    logger.debug('SmsCode6::setAutoFillCodes', 'auto fill', { code: value });
  };

  const onKeyPress = (index: number, e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Backspace') {
      return;
    }

    // remove last code
    const copiedCodes = [...codes];
    copiedCodes.pop(); // remove the last element
    setCodes(copiedCodes);

    // move focus previous
    let nextIndex = 0;
    if (index === 5 && copiedCodes.length === 5) {
      // last one removed, shall keep at the same position
      nextIndex = index;
    } else if (index - 1 >= 0) {
      nextIndex = index - 1;
    }
    focus(nextIndex);

    // reset global code
    const globalCode = copiedCodes.join('');
    code.setState(globalCode);

    logger.debug('SmsCode6::onKeyPress', 'delete action', { index: nextIndex, code: globalCode });
  };

  const onClick = () => {
    let index = 0;
    if (codes.length < 6) {
      index = codes.length;
    }
    focus(index);
    logger.debug('SmsCode6::onClick', 'manually click one cell, keep the focus', { focusIndex: index });
  };

  const onClear = () => {
    logger.debug('SmsCode6::onClear', 'clear all');
    focus(0);
    setCodes([]);
    code.setState('');
  };

  const renderCells = () => {
    const cells = [];
    for (let i = 0; i < codeLength; i++) {
      const el = (
        <input
          key={`SmsCode6-${i}`}
          type="tel"
          pattern="[0-9]*"
          className={styles.cell}
          ref={(el) => (focusRef.current[i] = el as HTMLInputElement)}
          value={codes[i] === undefined ? '' : codes[i]}
          onChange={(e) => setCode(i, e.target.value)}
          onClick={onClick}
          onKeyUp={(e) => onKeyPress(i, e)}
        />
      );
      cells.push(el);
    }
    return cells;
  };

  return (
    <div className={`${styles.SmsCode6} ${error.state ? styles.hasError : ''}`}>
      {renderCells()}
      <div className={styles.icon} onClick={onClear}>
        <Image src={icInputClear} className={styles.clearIcon} alt="clearIcon" width="18" height="18" />
      </div>
    </div>
  );
};

export default SmsCode6;
