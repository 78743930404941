import { Config } from '../config';
import { gql, request as requestGQL, RequestExtendedOptions as RequestOptionsGQL } from 'graphql-request';
import { genCurlFromGQLOptions, logger, isDev } from '../util';
import { MOCK_CONSTANTS } from '../../../test/mock/app-middleware/constant';
import * as Dom from 'graphql-request/dist/types.dom';

export interface FeatureToggleResponse {
  featureToggles: {
    enabled: boolean;
    name: string;
  }[];
}

export interface FeatureToggleInput {
  names: string[];
}

export enum MockAction {
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  HEADER_X_TOGGLE_RESPONSE_EMPTY = 'HEADER_X_TOGGLE_RESPONSE_EMPTY',
}

export const queryCaptchaToggle = async (config: Config, mock?: MockAction): Promise<boolean> => {
  logger.debug('API::featureToggles', 'start');
  const variables: FeatureToggleInput = { names: ['enableCaptchaMP'] };

  const options: RequestOptionsGQL<FeatureToggleInput> = {
    url: `${config.appMiddlewareHost}${config.appMiddlewareGqlEndpoint}?type=QueryFeatureToggle`,
    document: gql`
      query queryFeatureToggle($names: [String]!) {
        featureToggles(names: $names) {
          name
          enabled
        }
      }
    `,
    variables,
    requestHeaders: handleMockHeader(
      {
        'api-key': config.apiKey,
      },
      mock
    ),
  };
  const DEFAULT_CAPTCHA_MP_TOGGLE = true;
  try {
    logger.debug('API::featureToggle', 'curl command:', genCurlFromGQLOptions(options));
    const res = await requestGQL<FeatureToggleResponse, FeatureToggleInput>(options);
    logger.debug('API::featureToggle', 'res', res);
    if (!res.featureToggles) {
      return DEFAULT_CAPTCHA_MP_TOGGLE;
    }
    return res.featureToggles[0] ? res.featureToggles[0].enabled : DEFAULT_CAPTCHA_MP_TOGGLE;
  } catch (err) {
    logger.error('API::featureToggle', 'error', err, true);
    return DEFAULT_CAPTCHA_MP_TOGGLE;
  }
};

const handleMockHeader = (
  headers: Record<string, string>,
  action: MockAction | undefined
): Dom.RequestInit['headers'] => {
  if (!isDev()) {
    return headers;
  }
  if (action === undefined) {
    return headers;
  }

  switch (action) {
    case MockAction.INTERNAL_ERROR:
      headers[MOCK_CONSTANTS.HEADER_X_TOGGLE_INTERNAL_SERVER_ERROR] = 'true';
      break;
    case MockAction.HEADER_X_TOGGLE_RESPONSE_EMPTY:
      headers[MOCK_CONSTANTS.HEADER_X_TOGGLE_RESPONSE_EMPTY] = 'true';
      break;
    default:
      break;
  }

  return headers;
};
