import React from 'react';
import { NextPage } from 'next';
import styles from './Button.module.scss';

type ButtonProps = {
  text: string;
  onClick: () => void;
  disabled?: boolean;
};

const Button: NextPage<ButtonProps> = (props: ButtonProps) => {
  const { text, onClick } = props;
  let { disabled } = props;
  if (disabled === undefined) {
    disabled = false;
  }

  return (
    <button className={`${styles.HILButton} ${disabled ? styles.disabled : ''}`} onClick={onClick} disabled={disabled}>
      {text}
    </button>
  );
};

export default Button;
