import { Config } from '../config';
import { ClientError, gql, request as requestGQL, RequestExtendedOptions as RequestOptionsGQL } from 'graphql-request';
import { BindResponseCodes, PLATFORM_WECHAT } from '../constant';
import { genCurlFromGQLOptions, isDev, logger } from '../util';
import { MOCK_CONSTANTS } from '../../../test/mock/app-middleware/constant';
import * as Dom from 'graphql-request/dist/types.dom';

export interface BindInput {
  input: BindVariables;
}

export interface BindVariables {
  platform: string;
  authorizationCode: string;
  optIn: boolean;
  validation?: BindValidation2FA;
}

export interface BindValidation2FA {
  method: BindValidation2FAMethod;
  code: string;
}

export enum BindValidation2FAMethod {
  email = 'email',
  sms = 'sms',
}

export type BindResponse = BindErrorExt | BindErrorExtPayload2FARequired;

export interface BindError {
  message: string;
  extensions: BindErrorExt | BindErrorExtPayload2FARequired;
}

export interface BindErrorExt {
  code: BindResponseCodes;
}

export interface BindErrorExtPayload2FARequired extends BindErrorExt {
  delivery: {
    method: BindValidation2FAMethod;
    addressMasked: string;
  };
}

export enum MockAction {
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  VALIDATION_METHOD_SMS = 'VALIDATION_METHOD_SMS',
  VALIDATION_METHOD_EMAIL = 'VALIDATION_METHOD_EMAIL',
  NO_VALIDATION_METHOD = 'NO_VALIDATION_METHOD',
  VALIDATION_CONFLICT = 'VALIDATION_CONFLICT',
  TOO_MANY_ATTEMPTS = 'TOO_MANY_ATTEMPTS',
  TOO_MANY_REQUESTS = 'TOO_MANY_REQUESTS',
}

export const bind = async (
  config: Config,
  auth: string,
  wechatJsCode: string,
  validation?: BindValidation2FA,
  mock?: MockAction
): Promise<BindResponse> => {
  logger.debug('API::bind', 'start', { mock });
  const variables: BindInput = {
    input: {
      platform: PLATFORM_WECHAT,
      authorizationCode: wechatJsCode,
      optIn: true,
    },
  };
  if (validation !== undefined) {
    variables.input.validation = validation;
  }

  const options: RequestOptionsGQL<BindInput> = {
    url: `${config.appMiddlewareHost}${config.appMiddlewareGqlEndpoint}?type=bindWechatAccount`,
    document: gql`
      mutation linkAccount($input: LinkAccountInput!) {
        linkAccount(input: $input) {
          platform
        }
      }
    `,
    variables,
    requestHeaders: handleMockHeader(
      {
        authorization: auth,
        'api-key': config.apiKey,
      },
      mock
    ),
  };

  try {
    logger.debug('API::bind', 'curl command:', genCurlFromGQLOptions(options));
    await requestGQL<{ linkAccount: { platform: string } }, BindInput>(options);
    const res = { code: BindResponseCodes.DONE };
    logger.debug('API::bind', 'res', res);
    return res;
  } catch (err) {
    logger.error('API::bind', 'error', err, true);
    if (!(err instanceof ClientError)) {
      throw err;
    }

    let errorCaseRes;
    const error = err.response.errors?.shift() as BindError;
    if (!Object.values(BindResponseCodes).includes(error.extensions.code)) {
      errorCaseRes = { code: BindResponseCodes.INTERNAL_SERVER_ERROR };
    } else {
      errorCaseRes = error.extensions;
    }
    logger.debug('API::bind', 'res', errorCaseRes);

    return errorCaseRes;
  }
};

const handleMockHeader = (
  headers: Record<string, string>,
  action: MockAction | undefined
): Dom.RequestInit['headers'] => {
  if (!isDev()) {
    return headers;
  }
  if (action === undefined) {
    return headers;
  }

  switch (action) {
    case MockAction.UNKNOWN_ERROR:
      headers[MOCK_CONSTANTS.HEADER_X_LINK_UNKNOWN_SERVER_ERROR] = 'true';
      break;
    case MockAction.INTERNAL_ERROR:
      headers[MOCK_CONSTANTS.HEADER_X_LINK_INTERNAL_SERVER_ERROR] = 'true';
      break;
    case MockAction.VALIDATION_METHOD_SMS:
      headers[MOCK_CONSTANTS.HEADER_X_LINK_VALIDATION_METHOD] = 'sms';
      break;
    case MockAction.VALIDATION_METHOD_EMAIL:
      headers[MOCK_CONSTANTS.HEADER_X_LINK_VALIDATION_METHOD] = 'email';
      break;
    case MockAction.TOO_MANY_REQUESTS:
      headers[MOCK_CONSTANTS.HEADER_X_LINK_TOO_MANY_REQUESTS] = 'true';
      break;
    case MockAction.NO_VALIDATION_METHOD:
      headers[MOCK_CONSTANTS.HEADER_X_LINK_NO_VALIDATION_METHOD] = 'true';
      break;
    case MockAction.VALIDATION_CONFLICT:
      headers[MOCK_CONSTANTS.HEADER_X_LINK_VALIDATION_CONFLICT] = 'true';
      break;
    case MockAction.TOO_MANY_ATTEMPTS:
      headers[MOCK_CONSTANTS.HEADER_X_LINK_TOO_MANY_ATTEMPTS] = 'true';
      break;
    default:
      break;
  }

  return headers;
};
